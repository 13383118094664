import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { BookFormModule } from '@studiobuki/web-core/lib/book-form';
import { PreviewModule } from '@studiobuki/web-core/lib/preview';
import { SectionMessageDateModule } from '@studiobuki/web-core/lib/section-message-date';
import { SectionReviewsModule } from '@studiobuki/web-core/lib/section-reviews';
import { SectionStepsModule } from '@studiobuki/web-core/lib/section-steps';
import { SectionFaqModule } from '@studiobuki/web-core/lib/section-faq';
import { SectionFooterBannerModule } from 'src/app/shared/section-footer-banner/section-footer-banner.module';
import { SectionFooterBannersModule } from 'src/app/shared/section-footer-banners/section-footer-banners.module';
import { SafePipeModule } from 'safe-pipe';
import { ScrollTopModule } from 'src/app/shared/scroll-top/scroll-top.module';
import { YotpoModule } from '@studiobuki/web-core/lib/yotpo';
import { HomePageComponent } from './home-page.component';
import { BannerOneModule } from './components/banner-one/banner-one.module';

@NgModule({
  declarations: [HomePageComponent],
  imports: [
    CommonModule,
    SectionHeaderGapModule,
    BookFormModule,
    PreviewModule,
    SectionMessageDateModule,
    SectionReviewsModule,
    SectionStepsModule,
    SectionFaqModule,
    SectionFooterBannerModule,
    SectionFooterBannersModule,
    BannerOneModule,
    SafePipeModule,
    ScrollTopModule,
    YotpoModule,
  ],
})
export class HomePageModule {}
