<div class="container">
  <div class="container__img">
    <img
      class="img"
      src="assets/images/date-image.png"
      alt="Flying Anpanman"
    />
  </div>
  <div class="container__content">
    <div class="content">
      <div class="content__title">
        <p class="title">本日ご注文のお届け日</p>
      </div>
      <div class="content__date">
        <p class="date">{{ arrivalDate$ | async }}</p>
      </div>
      <div class="content__subtitle">
        <p class="subtitle">*一部地域除く</p>
      </div>
    </div>
  </div>
</div>
